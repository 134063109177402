.unlock {
    width: 442px;
    background-color: 'white';
    border-radius: 8;
}
.unlock-form {
    padding:40px;
}

.title {
    padding:20px;
}

.unlock-btn {
    border-radius: 45px;
    height: 45px;
    width: 390px;
    border: 1px solid #2A76EC;
    color: #2A76EC;
}

@media screen and (max-width: 512px)   {
    .unlock {
        width: 95%;
    }
    .unlock-form{
        padding: 10px;
    }
}