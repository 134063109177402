.details{

}
.moment-board{
    width:651px;
    padding: 25px 23px 0px 40px;
    background: #FFFFFF;
    margin-top: 15px;
    border-radius: 8px;
}
.moment-commands{
    width:651px;
    padding: 32px 23px 15px 40px;
    background: #FFFFFF;
    margin-top: 15px;
    border-radius: 8px;
}
.moment-commands-keyin{
    width: 100%;
}
.moment-commands-keyin>div:first-child>img{
    width: 42px;
    height: 42px;
    border-radius: 42px;
}
textarea::-webkit-scrollbar {
    height: 0;
    width: 0;
    color: transparent;
  }
  .moment-commands-keyin>div:nth-child(2){
    background: #F2F3F4;
    border-radius: 4px;
    width: 581px;
    height: 121px;
    margin-left: 10px;
  }
  .moment-commands-keyin>div>textarea{
    background-color: transparent;
    border: none;
    outline: none;
    width: 551px;
    padding: 15px 15px;
    height:91px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 29px;
    letter-spacing: -0.24px;
    color: #000000;
  }
  .commands-submit{
    width: 100%;
    justify-content: flex-end;
  }
  .commands-submit-btn{
    cursor: pointer;
    width: 68px;
    height: 31px;
    background: #2974EB;
    border-radius: 45px;
    color: #FFFFFF;
    font-size: 13px;
    margin-top: 17px;
    margin-right:20px;
    text-align: center;
    line-height:31px;
  }
  .commands-title{
    margin-top: 17px;
  }
  .commands-img{
    width: 42px;
    height: 42px;
    border-radius: 42px;
    object-fit: contain;
  }
  .commands-item{
    padding: 28px 0px;
    border-bottom: 1px solid #D9D9D9;
  }
  .commands-item:last-child{
    border-bottom: none;
  }
  .commands-item>div:first-child>span{
    display: inline-block;
    margin-left: 7px;
  }
  .commands-content{
    margin-top: 5px;
    width: 100%;
  }
  .commands-content>span{
      margin-left:50px;
  }
  .commands-content>div{
    user-select: none;
  }

@media screen and (max-width: 512px)   {
  .moment-board {
    box-sizing: border-box;
    width: 100%;
  }
  .moment-commands {
    width: 100%;
    box-sizing: border-box;
  }
  .moment-commands-keyin>div>textarea {
    max-width: 230px;
  }
}  