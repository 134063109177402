.c-c-active {
    font-family: 'PingFang SC';
font-style: normal;
font-weight: 600;
font-size: 17px;
line-height: 24px;
/* identical to box height */

letter-spacing: -0.24px;

color: #000000;
cursor: pointer;
}
.c-c-inactive {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    /* identical to box height */

    letter-spacing: -0.24px;

    /* #777777 */

    color: #777777;
    cursor: pointer;
}
.nameInput {
    width: 198px;
    height: 35px;
    left: 218px;
    top: 99px;

    background: #F2F3F4;
    border-radius: 6px;
    border-width: 0px;
}

.report-form {
    padding: 0px 25px;
    height: 422px;
}

.report-form .row {
    height: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.c-c-form textarea {
    width: 385px;
    height: 100px;
    left: 0px;
    top: 34px;
    
    background: #F2F3F4;
    border-radius: 6px;
    border-width: 0px;
}

.c-c-form .label {
    padding:12px 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;

    color: #000000;

}
.c-c-form .priceInput {
    width: 198px;
    height: 35px;
    left: 218px;
    top: 99px;

    background: #F2F3F4;
    border-radius: 6px;
    border-width: 0px;

}

.modal-cancel {
    /* width: 167px;
height: 45px; */
/* left: 0px;
top: 0px; */
    flex:1;
    height: 45px;
    /* #ABABAB */

    border: 1px solid #ABABAB;
    border-radius: 45px;
    margin-right: 22px;
    color: #777777;
}

.modal-confirm {
    
    height: 45px;
    flex: 1;
    margin-left: 22px;

    /* Linear */

    background: linear-gradient(91.22deg, #2A76EC 2.91%, #5DFFE9 95.46%);
    border-radius: 45px;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
    /* identical to box height */

    letter-spacing: -0.24px;

    color: #FFFFFF;
    justify-content: center;
    align-items: center;
    display: flex;
}

.report-form textarea {
    width: 391px;
    height: 257px;

    background: #F2F3F4;
    border-radius: 4px;
}


.select-form .row {
    height: 70px;
    padding:0px 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.select-form {
    padding-bottom: 40px;
}

.select-form .nexticon {
    width: 16px;
    height: 16px;
}