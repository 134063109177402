.c-c-active {
    font-family: 'PingFang SC';
font-style: normal;
font-weight: 600;
font-size: 17px;
line-height: 24px;
/* identical to box height */

letter-spacing: -0.24px;

color: #000000;
cursor: pointer;
}
.c-c-inactive {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    /* identical to box height */

    letter-spacing: -0.24px;

    /* #777777 */

    color: #777777;
    cursor: pointer;
}
.nameInput {
    width: 198px;
    height: 35px;
    left: 218px;
    top: 99px;

    background: #F2F3F4;
    border-radius: 6px;
    border-width: 0px;
}

.c-c-form {
    padding: 0px 25px;
}

.c-c-form .row {
    height: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.c-c-form textarea {
    width: 385px;
    height: 100px;
    left: 0px;
    top: 34px;
    
    background: #F2F3F4;
    border-radius: 6px;
    border-width: 0px;
}

.c-c-form .label {
    padding:12px 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;

    color: #000000;

}
.c-c-form .priceInput {
    width: 198px;
    height: 35px;
    left: 218px;
    top: 99px;

    background: #F2F3F4;
    border-radius: 6px;
    border-width: 0px;

}

.c-c-form .c-c-avatar {
    width: 40px;
    height: 40px;
}

.c-c-form .nextIcon {
    width: 16px;
    height: 16px;
}