.feedback {
    width: 1055px;
    padding: 24px 54px 39px 24px;
    width: 947px;
    background: #FFFFFF;
    border-radius: 8px;
    margin-top: 15px;
    overflow: hidden;
    
}
.fb-title{
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.24px;
    color: #000000;
}
.fb-container{
    width: 731px;
}
.fb-text{
    width: 100%;
    height: 136px;
    background: #F2F3F4;
    border-radius: 17px;
    margin-top:18px;
    margin-bottom:29px;
}
.fb-text>textarea{
    background-color: transparent;
    border: none;
    outline: none;
    width: 681px;
    padding: 25px 25px;
    height:86px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 29px;
    letter-spacing: -0.24px;
    color: #777777;
    
}

.fb-button{
    width: 320px;
    height: 45px;
    background: linear-gradient(91.22deg, #2A76EC 2.91%, #5DFFE9 95.46%);
    border-radius: 45px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 45px;
    letter-spacing: -0.24px;
    color: #FFFFFF;
    text-align: center;
    cursor: pointer;
    user-select: none;

}
.fb-button:active{
  opacity: 0.65;
  
}

textarea::-webkit-scrollbar {
    height: 0;
    width: 0;
    color: transparent;
  }
  .fb-upload{
    position: relative;
  }
  .fb-button-cxt{
    position: absolute;
    left:0;
    top: 0;
    z-index:111;
  }
  .fb-upload-img{
    display: inline-block;
    width: 25px;
    height:25px;
    cursor: pointer;
  }
  .fb-upload-input{
    position: absolute;
    left:0;
    top: 0;
    z-index: 333;
  }
  .fb-upload-input>input{
     opacity: 0;
     width:32px;
     height:32px;
  }

  @media screen and (max-width: 512px)   {
    .feedback {
      width: 100%;
      box-sizing: border-box;
    }
    .fb-container {
      width: 100%;
    }
    .fb-button {
      width: 250px;
    }
  }