.footer {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top:39px;
    margin-bottom: 30px;
}
.footer .item {
    margin-left: 10px;
    margin-right: 10px;
    color: #ababab;
    font-size: 13px;
    cursor: pointer;
}
.footer .navi {
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;    
}
.footer .cert{
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;    
    margin-top: 15px;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 19px;
    /* identical to box height */


    /* #ABABAB */

    color: #ABABAB;
}

