.reward-form {
    padding:20px 40px;
}

.title {
    padding:20px;
}

.reward-btn {
    border-radius: 45px;
    height: 45px;
    width: 390px;
    border: 1px solid #2A76EC;
    color: #2A76EC;
}
.price-block {
    width: 106px;
    height: 83px;
    background: #F2F7FF;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #2A76EC;
    margin: 9px;
}

.price-block.selected {
    background-color: #2974EB;
    color: white;
}

.reward-form .label {
    white-space: nowrap;
    padding-top: 20px;
}

.reward-form .custom {
    height: 45px;
    align-items: center;
}

.reward-form .custom input {
    background: #F2F3F4;
    border-radius: 6px;
    border-width: 0px;
    margin-left: 20px;
    padding: 8px;
}
.reward-submit {
    width: 320px;
    height: 45px;
    left: 35px;
    top: 8px;
    background: linear-gradient(91.22deg, #2A76EC 2.91%, #5DFFE9 95.46%);
    border-radius: 45px;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
    letter-spacing: -0.24px;
    color: #FFFFFF;
    justify-content: center;
    align-items: center;
    display: flex;
}
.reward-submit.disabled {
    background:#777;
}