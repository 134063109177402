.charge{
    padding:30px 35px 79px 35px;
    width:9;
    background: #FFFFFF;
    border-radius: 12px;
    overflow: hidden;
    margin-top:28px;
}
.charge-title{
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    color: #000000;
}
.charge-title>img{
    width: 25px;
    height: 19px;
    display: inline-block;
    margin-right: 17px;
}

.charge-account-desc{
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    color: #000000;
    margin-top:53px;
}
.charge-account-desc>span{
    font-weight: 600;
    font-size: 26px;
    color: #2974EB;
}

.charge-account-info>div:first-child{
    margin-top:20px;
}

.charge-account-info>div:nth-child(2){
    margin-top:23px;
}
.charge-account-info>div:nth-child(3){
    margin-top:27px;
}


.payment-btn{
    cursor: pointer;
        background: linear-gradient(91.22deg, #2A76EC 2.91%, #5DFFE9 95.46%);
        border-radius: 12px;
        width: 157px;
        height: 57px;
        text-align: center;
        line-height: 57px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        letter-spacing: -0.24px;
        color: #FFFFFF;
        margin-top:20px;
}

.payment-desc{
    width: 823px;
    padding:25px 25px;
    background: #FFFDEE;
    border-radius: 8px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    letter-spacing: -0.24px;
    color: #000000;
}

.payment-desc p {
    margin-block-start: 0.5rem;
    margin-block-end: 0.5rem;
}

.payment-desc-title{
    
}

.charge-money-item.selected {
    color: white;
    background-color: #2974EB;
}

.charge-money-item.selected .charge-money-num{
    color:white;
}

.money-label {
    margin-right: 20px;
}
.money-input{
    background-color: #f2f3f4;
    border-radius: 12px;
    padding:8px;
    border-width: 0px;
}