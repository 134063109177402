.my {
    font-size: 15px;
    background-color: white;
    width: 970px;
    min-height: 200px;
    margin: 28px;
    padding: 22px;
    border-radius: 12px;
}

.my .indicator {
    width: 25px;
    height: 19px;
    margin-right: 17px;
}

.my .top {
    margin-bottom: 30px;
}

.my .title {
    font-weight: 600;
    font-size: 26px;
    line-height: 36px;
}

.my .avatar {
    width:77px;
    height: 77px;
}
.my .name {
    font-weight: 600;
    font-size: 26px;
    line-height: 36px;

    color: #000000;
}
.my .namevalue {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
}

.my .value-block {
    flex:1;
    display:flex;
    align-items: center;
}

.my .value-block.rightline {
    border-right: 1.5px solid #D9D9D9;
    
}
.my .value-block .numvalue {
    font-weight: 600;
    font-size: 30px;
    line-height: 42px;
    /* identical to box height */


    /* Style */

    color: #2974EB;
}

.withdraw {
    cursor: pointer;
    width: 68px;
    height: 31px;
    left: 0px;
    top: 0px;

    /* Style */

    background: #2974EB;
    border-radius: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}
.withdraw.disabled {
    background: #ababab;
}


.my .account-tab {
    margin-top: 50px;
}

.my .account-tab .tab {
    cursor: pointer;
    font-weight: 600;
    margin-right: 111px;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 31px;
    /* identical to box height */
    /* #ABABAB */
    color: #ABABAB;
}

.my .account-tab .tab.active {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 31px;
    /* identical to box height */
    color: #000000;
}

.my .account-tab .bline {
    width: 33px;
    height: 6px;


    /* Linear */

    background: linear-gradient(91.22deg, #2A76EC 2.91%, #5DFFE9 95.46%);
    border-radius: 10px;
}

.my .account-data {
    margin-top: 28px;
}

.my .account-data .row {
    border-top: 1.5px solid #D9D9D9;
    display: flex;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    height: 57px;
    align-items: center;
}

.my .account-data .row div:nth-child(1){
    width: 114px;
}

.my .account-data .row div:nth-child(2){
    flex: 1;
}
.my .account-data .row div:nth-child(3){
    width: 80px;
}
.my .account-data .row div:nth-child(4){
    width: 80px;
}
.my .account-data .row div:nth-child(5){
    width: 80px;
} 

.my .account-data .row.data {
    color: #777;
    font-weight: 400;
    font-size: 13px;
}


.my .account-data .row div {
    padding:0px 20px;
}

.btn-getcode {
    width: 95px;
    height: 39px;
    

    /* Linear */

    background: linear-gradient(91.22deg, #2A76EC 2.91%, #5DFFE9 95.46%);
    border-radius: 45px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height */

    letter-spacing: -0.24px;

    color: #FFFFFF;
    cursor: pointer;
}

.withdraw-form{
    padding: 0px 25px;
}

.withdraw-form .row {
    height: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.withdraw-form .row .label{
    width: 200px;
}


.withdraw-form .value-input {
    
    height: 35px;
    left: 218px;
    top: 99px;

    background: #F2F3F4;
    border-radius: 6px;
    border-width: 0px;
}

.withdraw-form .code-input {
    width: 68px;
    height: 35px;
    left: 218px;
    top: 99px;

    background: #F2F3F4;
    border-radius: 6px;
    border-width: 0px;
}

.withdraw-notice p{
    margin-block-end: 0.1rem;
    margin-block-start: 0.1rem;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: -0.24px;
    color: #FF6565;
}

.withdraw-notice {
    margin-bottom: 20px;
}