.App {
  /* text-align: center; */
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.fx-box {
  display: flex;
}

.fx-jc-c {
  justify-content: center;
}

.fx-jc-e {
  justify-content: flex-end;
}

.fx-ai-c{
  align-items: center;
}
.fx-ai-s{
  align-items:flex-start;
}

.fx-jc-b{
  justify-content: flex-start;
}

.fx-jc-b{
  justify-content: flex-end;
}
.fx-jc-sb{
  justify-content: space-between;
}

.fx-1{
  flex:1
}

.fx-05{
  flex:0.5
}

.fx-fd-r{
  flex-direction: row;
}
.fx-fd-c{
  flex-direction: column;
}



.header {
  height: 97px;
}

.fx-wrap {
  flex-wrap: wrap;
}

.color-white {
  color:white;
}

.color-fu {
  color:#FD66EE;
}

.color-s {
  color:#a0a0a0;
}

.font-size13 {
  font-size: 13px;
}
.font-size16{
  font-size: 16px;
}
.font-size17 {
  font-size: 17px;
}

.font-size26 {
  font-size: 26px;
}

.btn {
  cursor: pointer;
}


.section {
  border-radius: 12px;
  margin: 26px;
  padding: 20px;
  background: #FFFFFF;
}


.section-header{
  height: 80px;
  line-height: 36.4px;
  font-size: 26px;
  margin-left: 11px;
}

.section-header .title {
  margin-left: 10px;
}

.header-icon {
  width: 47px;
  height: 47px;
}
.layout-ml20{
  margin-left:20px;
}

.basic-theme-font{
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
}
.fontw4{
  font-weight: 400;
}
.fontw5{
  font-weight: 500;
}
.fontw6{
  font-weight: 600;
}
.fontb{
  font-weight: bold;
}

.charge-label{
  width: 85px;
}
.charge-text{
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  color: #000000;
}

.charge-payment-item{
  width: 106px;
  height: 46px;
  left: 0px;
  top: 0px;
  border-radius: 8px;
}
.charge-payment-item>img{
  margin-right:3px;
  width:20px;
  height: 20px;
  display: inline-block;
  margin-left: 12px;
}
.charge-payment-item>span{
  margin-right: 12px;
}
.charge-payment-item-ali{
  border: 2px solid #2974EB;
}
.charge-payment-item-wx{
  border: 2px solid #5BE381;
}

.charge-money{
  width: 620px;
  display: grid;
  grid-template-columns: repeat(5,106px);
  gap:18px;
}
.charge-money-item{
  width: 106px;
  height: 83px;
  background: #F2F7FF;
  border-radius: 12px;
}
.charge-money-item:active,.payment-btn:active{
  opacity: 0.65;
}
.charge-money-num{
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  letter-spacing: -0.24px;
  color: #2974EB;
  user-select: none;
}
.charge-money-unit{
  font-size: 15px;
}
.charge-money-item>div>span{
  display: inline-block;
  margin-left:3px;
}
.font-color1{
  color: #2974EB;
}
.font-color2{
color: #A0A0A0;
}
.font-color3{
  color: #000000;
}
.font-color4{
  color: #ABABAB
}
.font-color5{
  color: #999999
}
.font-color6{
  color: #777777;
}