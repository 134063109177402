.search{
    margin-top:15px;
}

.search-list{
    margin-left: 11px;
}

@media screen and (max-width: 512px) {
    
    .search-list {
        margin:0px;    
        flex: 1;
    }
    
}