.c-c-inactive {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    letter-spacing: -0.24px;
    color: #777777;
    cursor: pointer;
}
.pay-items{
    padding-left: 48px;
    padding-right: 48px;
}
.coin-text{
      margin-left: 8px;
      display: inline-block;
}
.pay-items>div:nth-child(2){
    margin-top: 20px;
}

.pay-items>div:nth-child(3){
    margin-top:27px;
}
.charge-button{
    background: linear-gradient(91.22deg, #2A76EC 2.91%, #5DFFE9 95.46%);
    border-radius: 100px;
    width:577px;
    height:57px;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.24px;
    color: #FFFFFF;
    margin-left:48px;
    margin-top:48px;
    text-align: center;
    line-height: 57px;
}

.close-button {
    width: 54px;
    height: 54px;
    position:absolute;
    right:10px;
    top:0px;
}

.dialog-header {
    height: 60px;
    align-items: center;
    justify-content: center;
    padding-left: 32px;
}