
.suggestion {
    padding: 15px;
    background-color: white;
    margin-top: 15px;
    border-radius: 12px;
    max-width: 288px;
}

.suggestion .items .item {
    display: flex;
    flex-direction: row;
    margin-bottom: 22px;
    max-height: 60px;
}

.suggestion .item img {
    width: 51px;
    height: 51px;
}

.suggestion .item .info {
    flex-direction: column;
    margin-left:10px;
    display: flex;
    justify-content: center;
}


.suggestion .item .sub {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 15px;
    margin-top: 5px;
    color: #9A9A9A;
    overflow: hidden;
}

.suggestion .refresh {
    cursor: pointer;
}

@media screen and (max-width: 512px)  {
    .suggestion {
        display: none;
    }
}